<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6444_183520)">
      <path
        d="M2.87677 42.4792C-6.39756 33.3219 7.98623 4.2748 28.4026 0.58485C51.3093 -3.55509 67.3677 14.4446 63.396 35.1893C59.0165 58.0715 30.206 69.5013 21.9836 61.379C17.1532 56.609 25.2038 50.7591 19.2786 44.8867C13.3319 39.0143 7.19191 46.7542 2.8553 42.4792H2.87677Z"
        fill="var(--v-illustrations-lighten2)"
      />
      <path
        d="M22 48L41 48"
        stroke="var(--v-illustrations-darken1)"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M32 25V45"
        stroke="var(--v-illustrations-darken1)"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M46.0273 23.5198C45.233 23.5198 44.5031 23.6998 43.859 24.0373C43.859 23.6998 43.902 23.4298 43.902 23.0923C43.902 16.9724 39.0501 12 33.1034 12C28.1227 12 23.9149 15.5099 22.6698 20.2349C21.6608 19.6724 20.4585 19.3349 19.2134 19.3349C15.2417 19.3349 12 22.6423 12 26.7373C12 30.8322 15.2417 34.1397 19.2134 34.1397H46.0488C48.9041 34.1397 51.2227 31.7772 51.2227 28.8298C51.2227 25.8823 48.9041 23.5198 46.0488 23.5198H46.0273Z"
        fill="var(--v-illustrations-lighten3)"
        stroke="#797997"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32 51C33.6569 51 35 49.6569 35 48C35 46.3431 33.6569 45 32 45C30.3431 45 29 46.3431 29 48C29 49.6569 30.3431 51 32 51Z"
        fill="var(--v-illustrations-lighten3)"
        stroke="var(--v-illustrations-darken1)"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M43 50C44.1046 50 45 49.1046 45 48C45 46.8954 44.1046 46 43 46C41.8954 46 41 46.8954 41 48C41 49.1046 41.8954 50 43 50Z"
        fill="var(--v-illustrations-lighten1)"
        stroke="#797997"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 50C22.1046 50 23 49.1046 23 48C23 46.8954 22.1046 46 21 46C19.8954 46 19 46.8954 19 48C19 49.1046 19.8954 50 21 50Z"
        fill="var(--v-illustrations-lighten1)"
        stroke="#797997"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35 28L29 22"
        stroke="var(--v-illustrations-darken1)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29 28L35 22"
        stroke="var(--v-illustrations-darken1)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6444_183520">
        <rect width="64" height="64" fill="var(--v-illustrations-lighten3)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>